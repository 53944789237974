.app-header {
    align-items: center;
    background: rgb(241, 241, 241);
    display: flex;
    justify-content: safe;
    padding: 0 2rem;
}

.app-header__logo {
    background: rgb(241, 241, 241);
    height: 35px;
    width: 45px;
}